import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import RightPopUp from "./Drawer";

import Logo from "../../assets/Navbar/logo.png";
import ArrowBlue from "../../assets/Navbar/arrow-blue.png";
import ArrowWhite from "../../assets/Navbar/arrow-white.png";
import Key from "../../assets/Navbar/key.png";

function appBarLabel(label) {
  return (
    <Toolbar>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <RightPopUp />
      </IconButton>
    </Toolbar>
  );
}

function ResultNavigation(props) {
  return (
    <>
      <nav className="nav-mobile">
        <div className="nav-logo">
          {props.showLogo ? 
            <button onClick={props.openMenu} type="button">
              <img src={props.logo} alt="Ownerscope Logo" />
            </button>
          : <p style={{
              color: "#4565AA",
              fontFamily: "Poppins",
              fontSize: "1.5rem",
              fontWeight: "600",
              margin: "0 auto",
              paddingLeft: "1rem",
            }}>{props.progress}</p>
          }
        </div>
        <div className="nav-links">
          <a href="#" className="nav-links-quiz" style={{
            width: `calc(100vw - ${props.progressWidth}px)`,
            zIndex: "1000",
          }}>
            <img src={props.arrowWhite} alt="Arrow" style={{marginLeft: "-1px"}}/>
          </a>
          <a href={props.keyUrl} className="nav-links-key">
            <img src={props.arrowBlue} alt="Arrow" />
            <img src={props.keyIcon} alt="Key" />
          </a>
        </div>
      </nav>

      <div className="nav-menu" id="nav-menu">
        <div class="nav-menu-overlay" id="nav-menu-overlay">
          <ul>
            <li><a href={props.quizUrl}>Take Quiz</a></li>
            <li><a href="#full-circle-definition">FAQ</a></li>
            <li><a href={props.keyUrl} target="_blank">Use Key</a></li>
            <li><a href={`${process.env.REACT_APP_MAIN_URL}/contact`}>Contact Us</a></li>
          </ul>
        </div>
      </div>
    </>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#224388",
    },
  },
});

export default function AppMenu(props) {

  const ToggleMenu = () => {
    const nav_menu = document.getElementById("nav-menu");
    const nav_menu_overlay = document.getElementById("nav-menu-overlay");
    if (nav_menu.style.display === "block") {
      nav_menu.style.display = "none";
      nav_menu_overlay.removeEventListener('click', () => {
        nav_menu.style.display = "none";
      });
    } else {
      nav_menu.style.display = "block";
      nav_menu_overlay.addEventListener('click', () => {
        nav_menu.style.display = "none";
      });
      window.onscroll = function() {
        nav_menu.style.display = "none";
      }
    }
  };

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          {props.showNav ? 
            <ResultNavigation
              quizUrl={process.env.REACT_APP_QUIZ_URL}
              keyUrl={process.env.REACT_APP_MAIN_URL + (!props.showLogo && props.progressWidth < 280 ? `/key?status=ongoing` : "/key")}
              logo={Logo}
              arrowWhite={ArrowWhite}
              arrowBlue={ArrowBlue}
              keyIcon={Key}
              openMenu={ToggleMenu}
              progress={props.progress}
              progressWidth={170 + props.progressWidth}
              showLogo={props.showLogo}
            />  
          : appBarLabel("Ownerscope")}
        </AppBar>
      </ThemeProvider>
    </Stack>
  );
}